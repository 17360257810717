import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";

export const queryCache = new QueryCache();
export const mutationCache = new MutationCache();

export const queryClient = new QueryClient({
  queryCache: queryCache,
  mutationCache: mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0, // always refresh if online
      gcTime: Infinity,
    },
    mutations: {
      gcTime: Infinity,
    },
  },
});
