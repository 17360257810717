import { ConfigProvider } from "antd";
import arEG from "antd/lib/locale/ar_EG";
import enUS from "antd/lib/locale/en_US";
import { t } from "i18next";
import { FC, Suspense, useEffect } from "react";

import i18n from "./assets/i18n";
import { Loader } from "./components";

import { useSelector } from "react-redux";
import { DirectionEnum, LanguagesEnum } from "./models/general/lang";
import { ExpFormProvider } from "./pages/experiences/helpers/useExpForm";
import AppRoutes from "./routes";
import "./styles/main.scss";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./network/queryClient";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App: FC = (): JSX.Element => {
  const { lang } = useSelector(
    (state: { base: { lang: string } }) => state.base
  );

  useEffect(() => {
    document.body.dir =
      lang === LanguagesEnum.AR ? DirectionEnum.RTL : DirectionEnum.LTR;
    document.documentElement.setAttribute("lang", lang);
    document.title = t("suplift");
  }, [lang]);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#ff5d5d",
          },
          components: {
            Layout: {
              headerBg: "#ffff",
            },
            Button: {
              colorBorder: "#ff5d5d",
              colorPrimary: "#ff5d5d",
            },
          },
        }}
        locale={i18n.language === LanguagesEnum.EN ? enUS : arEG}
        direction={i18n.dir(i18n.language)}
      >
        <Suspense fallback={<Loader />}>
          <ExpFormProvider>
            <AppRoutes />
          </ExpFormProvider>
        </Suspense>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;
