import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "../network/apiEndPoints";
import { axiosInstance } from "../network/apis";

type City = {
  id: number;
  name: string;
  nameEn: string;
};

export const useGetAllCities = () => {
  const query = useQuery({
    queryKey: ["cities"],
    queryFn: async () => {
      const restult = await axiosInstance.get<City[]>(
        API_ENDPOINTS.lookups.cities
      );

      return restult.data;
    },
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query.data || [],
  };
};
