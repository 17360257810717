import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "../network/apiEndPoints";
import { axiosInstance } from "../network/apis";

type Region = {
  id: number;
  name: string;
  nameEn: string;
  countryId: number;
};

export const useGetAllRegions = () => {
  const query = useQuery({
    queryKey: ["regions"],
    queryFn: async () => {
      const restult = await axiosInstance.get<Region[]>(
        API_ENDPOINTS.lookups.regions
      );

      return restult.data;
    },
    staleTime: Infinity,
  });

  return {
    ...query,
    data: query.data || [],
  };
};
